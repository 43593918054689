body {
  font-family: Montserrat, sans-serif;
  margin: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

.app_bar{
  height: 50px;
  background-color: #19227D;
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.default_button{
  background-color: #19227D;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  border: none;
  padding: 10px 15px;
  font-size: 12px;
}